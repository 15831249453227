export default function () {
  const composeApiUrl = (relPath, queryStringData_ar = []) => {
    // we cant include api name in axios.defaults.baseURL, cause we want to eval the host before we set it
    // set address
    let address;
    if (process.env.NODE_ENV == 'development') {
      // when npm run serve on localhost
      address = 'http://localhost:3000'
    } else {
      // when deployed prod
      address = 'https://vajper-api.webomaten.se'
    }
    address += relPath;
    return composeCachelessUrl(address, queryStringData_ar, true)
  }

  const composeCachelessUrl = (address, queryStringData_ar = []) => {
    return composeUrl(address, queryStringData_ar, true)
  }

  const composeVersionForUrl = (version) => {
    return version.replace(/\./g, '_')
  }

  const composeUrl = (address, queryStringData_ar = [], cacheless) => {
    if (cacheless) {
      queryStringData_ar.push({
        name: 'nocache',
        value: new Date().getTime()
      })
    }
    if (!queryStringData_ar) {
      return address
    }
    return appendQueryString(address, queryStringData_ar)
  }

  const appendQueryString = (address, queryStringData_ar) => {
    if (!queryStringData_ar) {
      return address
    }
    let queryString = convertQueryStringDataArrayQueryString(queryStringData_ar)
    return address + '?' + queryString
  }

  const convertQueryStringDataArrayQueryString = (queryStringData_ar) => {
    let queryString_ar = []
    for (let queryStringData of queryStringData_ar) {
      let item = queryStringData.name
//        if (hasProperty(queryStringData, 'value')) {
        item += '=' + queryStringData.value
//        }
      queryString_ar.push(item)
    }
    return queryString_ar.join('&')
  }

  return {
    composeApiUrl,
    composeCachelessUrl,
    composeVersionForUrl,
    composeUrl,
    appendQueryString,
    convertQueryStringDataArrayQueryString,
  }
}
