<template>
  <v-snackbar
    v-model="show"
    top
    timeout="-1"
  >
    <div v-html="message"></div>
    <div class="d-flex justify-center">
      <v-btn
        v-if="sticky"
        color="pink"
        text
        @click="doClose()"
      >
        Stäng
      </v-btn>
    </div>
  </v-snackbar>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import { appBus } from '../main'

export default {
  name: 'SnackBar',
  setup() {
    const show = ref(false);
    const message = ref('');
    const sticky = ref(false);

    const showMessage = (_message, _sticky = false) => {
      message.value = _message;
      sticky.value = !!_sticky;
      show.value = true;

      // only autohide if sticky is false (not if it is null or undefined)
      if (_sticky === false) {
        // auto hide
        setTimeout(() => {
          doClose();
        }, 2500);
      }
    }

    const doClose = () => {
      show.value = false;
    }

    const doOnMounted = () => {
      appBus.$on('showMessage', showMessage);
      appBus.$on('closeMessage', doClose);
    }

    onMounted(doOnMounted);

    return {
      show,
      message,
      sticky,
      doClose,
    }
  }
}
</script>