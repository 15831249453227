<template>
  <v-app>
    <v-app-bar
      v-if="useAppBar"
      class="grey darken-4"
      app
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>vajper</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      v-if="useNavigationDrawer"
      app
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :clipped="false"
    >
      <v-menu
        v-model="profileMenu"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            class="grey darken-4"
            style="height:96px"
          >
            <v-list-item-avatar>
              <v-img src="@/assets/vajper-avatar.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content tappable>
              <v-list-item-title class="text-h6 white--text">
                {{ loggedInData.winName }}
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text" v-if="loggedInData.pris.length > 1">Butik: {{ loggedInData.priName }}</v-list-item-subtitle>
              <v-list-item-subtitle class="grey--text">{{ loggedInData.usrName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-card
          min-width="300"
          offset-y
          class="grey lighten-3"
        >
          <v-list
            nav
            dense
          >
            <v-list-item
              @click="editProfile()"
            >
              <v-list-item-title>Redigera profil</v-list-item-title>
            </v-list-item>
            <template v-if="pris_other.length">
              <v-subheader>BYT BUTIK</v-subheader>
              <v-list-item
                v-for="pri of pris_other"
                :key="pri.pri_id"
                @click="switchPri(pri)"
              >
                <v-list-item-title>{{ pri.pri_str_name }}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-if="wins_other.length">
              <v-subheader>BYT WEB</v-subheader>
              <v-list-item
                v-for="win of wins_other"
                :key="win.win_id"
                @click="switchWin(win)"
              >
                <v-list-item-title>{{ win.win_str_name }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <!--
          <v-list>
            <v-list-item>
              <v-list-item-avatar size="62">
                <v-icon size="50">mdi-account-circle-outline</v-icon>
                <!-
                <v-img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-img>
                ->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ agentName }}</v-list-item-title>
                <v-list-item-subtitle v-if="agentType == 'company'">{{ userName }}</v-list-item-subtitle>
                <v-list-item-title>
                  <v-btn
                    class="mt-2"
                    small
                    depressed
                    color="secondary"
                    :to="{ name: 'my-profile' }"
                  >
                    Min profil
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          -->
          <v-card-actions
            class="grey darken-3"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="logout()"
              small
              color="white"
            >
              <v-icon left>mdi-logout</v-icon>
              Logga ut
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-list
        nav
        dense
        class="vajper-main-menu"
      >
        <template v-for="item in loggedInData.mainMenuItems">
          <template v-if="item.type == 'group'">
            <v-list-group
              v-if="item.items"
              v-model="item.active"
              :prepend-icon="item.icon"
              :key="item.id"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="child in item.items"
                :key="child.name"
                :to="child.to"
                style="padding-left:64px"
                link
              >
                <v-list-item-title v-text="child.name"></v-list-item-title>
                <v-list-item-icon v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else-if="item.type == 'headline'">
            <div class="vajper-main-menu-headline" :key="item.id">{{ item.name }}</div>
          </template>
          <template v-else>
            <v-list-item
              :to="item.to"
              link
              :key="item.id"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name}}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>

      <!--
      <v-list
        nav
        dense
      >

        <v-list-item
          :to="{ name: 'home' }"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hem</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="mainMenuItem of loggedInData.mainMenuItems"
          :key="mainMenuItem.label"
          :to="mainMenuItem.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ mainMenuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ mainMenuItem.name }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          :value="false"
          prepend-icon="mdi-cart-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>Ordrar</v-list-item-title>
          </template>
          <v-list-item
            :to="{ name: 'orderPut' }"
            link
          >
            <v-list-item-content>
              <v-list-item-title>Ny order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      -->
    </v-navigation-drawer>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--overlay-->
    <app-overlay></app-overlay>
    <!--alert-->
    <app-alert></app-alert>
    <v-main :class="{ 'grey lighten-1': !isLoggedIn, 'grey lighten-4': isLoggedIn }">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view @layoutData="onLayoutData" :act="viewAct" @actConsidered="onActConsidered"></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed, reactive } from '@vue/composition-api'
import SnackBar from './components/SnackBar'
import Overlay from './components/Overlay'
import Alert from './components/Alert'
import useCore from './helpers/core'
// import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'

export default {
  name: 'App',
  components: {
    'app-snack-bar': SnackBar,
    'app-overlay': Overlay,
    'app-alert': Alert,
  },
  setup (props, context) {
    // Vue 2
    const store = require('@/store/store').default;
    const router = require('@/router/router').default;

    const { callApi } = useCore();

    // const store = useStore();

    // const { t } = useI18n({
    //   messages: {
    //     sv: {
    //       'Username': 'Användarnamn',
    //       'Password': 'Lösenord',
    //       'Try again in': 'Försök igen om',
    //       'Sign out': 'Logga ut',
    //       'Byt till svenska': 'In english',
    //     },
    //   }
    // });

    const t = {
      'Username': 'Användarnamn',
      'Password': 'Lösenord',
      'Try again in': 'Försök igen om',
      'Sign out': 'Logga ut',
      'Byt till svenska': 'In english',
    }

    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const useNavigationDrawer = computed(() => isLoggedIn.value && store.getters.isFullLayoutMode);
    const useAppBar = computed(() => isLoggedIn.value && store.getters.isFullLayoutMode && context.root.$vuetify.breakpoint.smAndDown);
    const loggedInData = computed(() => store.getters.loggedInData);
    const selectedMenuItem = ref(0);
    const drawer = ref(null);
    const profileMenu = ref(false);
    const layoutData = reactive({
      headline: '',
      buttons: [],
    });
    const layoutData_default = null;
    const viewAct = ref('');

    const logout = () => {
      store.dispatch('logout')
        .then(() => {
          router.replace({ name: 'login' });
        });
    }

    const editProfile = () => {
      alert('Din profil kan endast redigeras från Kobra f n');
    }

    const onLayoutData = data => {
      layoutData.value = Object.assign({}, layoutData_default, data);
    }

    const onButtonClick = button => {
      console.log('App.vue onButtonClick');
      viewAct.value = button.act;
    }
    
    const onActConsidered = () => {
      viewAct.value = '';
    }

    const wins_other = computed(() => store.state.loggedInData.wins.filter(win => win.win_id != store.state.loggedInData.winId));
    const pris_other = computed(() => store.state.loggedInData.pris.filter(pri => pri.pri_id != store.state.loggedInData.priId));

    const switchWin = win => {
      profileMenu.value = false;
      const options = {
        method: 'post',
        path: '/cms/auth/switch-win',
        locale: 'sv',
        data: {
          winId: win.win_id,
        }
      }
      callApi(options)
        .then(res => {
          const dataToDispatch = {
            loggedInData: res.loggedInData,
          }
          store.dispatch('onAuth', dataToDispatch);
          router.replace(`/home/${res.loggedInData.uniqueLabel}`);
        })
        .catch(err => {
          console.err(err);
        });
    }

    const switchPri = pri => {
      profileMenu.value = false;
      const options = {
        method: 'post',
        path: '/cms/auth/switch-pri',
        data: {
          priId: pri.pri_id,
        },
      };
      callApi(options)
        .then((res) => {
          const dataToDispatch = {
            loggedInData: res.loggedInData,
          };
          store.dispatch('onAuth', dataToDispatch);
          router.replace(`/home/${res.loggedInData.uniqueLabel}`);
        })
        .catch((err) => {
          console.err(err);
        });
    };

    return {
      isLoggedIn,
      selectedMenuItem,
      drawer,
      layoutData,
      viewAct,
      loggedInData,
      logout,
      onLayoutData,
      onButtonClick,
      onActConsidered,
      t,
      switchWin,
      switchPri,
      wins_other,
      pris_other,
      editProfile,
      profileMenu,
      useNavigationDrawer,
      useAppBar,
    }
  },
  created() {
    this.layoutData_default = Object.assign({}, this.layoutData);
  }
};
</script>
