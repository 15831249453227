import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedInData: null,
    accessToken: null,
    firstRouterHasLoaded: false,
    locale: 'sv',
    layoutMode: 'full',
    targetOrigin: null,
    isLocalKobraParent: null,
  },
  mutations: {
    SET_LANGUAGE(state, value = 'sv') {
      state.language = value;
    },
    SET_LOGGED_IN_DATA(state, value = null) {
      state.loggedInData = value;
    },
    SET_ACCESS_TOKEN(state, value = null) {
      state.accessToken = value;
    },
    SET_LOGGED_OUT(state) {
      state.loggedInData = null;
      state.accessToken = null;
    },
    SET_FIRST_ROUTER_HAS_LOADED(state) {
      state.firstRouterHasLoaded = true;
    },
    SET_LAYOUT_MODE(state, value) {
      state.layoutMode = value;
    },
    SET_TARGET_ORIGIN(state, value) {
      state.targetOrigin = value;
    },
  },
  getters: {
    hasKobraModule: state => (label) => {
      return state.loggedInData.kobraModules.indexOf(label) !== -1;
    },
    useFullscreenDialog: state => ['embedded', 'popup-embedded'].includes(state.layoutMode),
    isFullLayoutMode: state => state.layoutMode == 'full',
    isPopupLayoutMode: state => state.layoutMode == 'popup',
    isLoggedIn: state => !!state.loggedInData,
    loggedInData: (state, getters) => {
      if (getters.isLoggedIn) {
        return state.loggedInData;
      }
      return null;
    },
    winId: (state, getters) => {
      return getters.isLoggedIn ? state.loggedInData.winId : 0;
    },
    priId: (state, getters) => {
      return getters.isLoggedIn ? state.loggedInData.priId : 0;
    },
  },
  actions: {
    switchLanguage({ commit }, value) {
      commit('SET_LANGUAGE', value);
    },
    setFirstRouterHasLoaded({ commit }) {
      commit('SET_FIRST_ROUTER_HAS_LOADED', true);
    },
    setLayoutMode({ commit }, value) {
      if (['full', 'popup', 'embedded', 'popup-embedded'].includes(value)) {
        commit('SET_LAYOUT_MODE', value);
      }
    },
    setTargetOrigin({ commit }, value) {
      commit('SET_TARGET_ORIGIN', value);
    },
    setAccessToken({ commit }, value) {
      commit('SET_ACCESS_TOKEN', value);
    },
    onAuth({ commit }, data) {
      commit('SET_LOGGED_IN_DATA', data.loggedInData);
      if (data.accessToken) {
        //accessToken is not sent when getting logged in data, switching accounts e.g
        //store accessToken in store
        commit('SET_ACCESS_TOKEN', data.accessToken);
        //store accessToken in localStorage
        setLocalStorage('accessToken', data.accessToken);
      }
      if (data.loggedInData.winId) {
        //store winId in localStorage in order to make it survive on reloads
        setLocalStorage('winId', data.loggedInData.winId);
      }
      if (data.loggedInData.priId) {
        //store winId in localStorage in order to make it survive on reloads
        setLocalStorage('priId', data.loggedInData.priId);
      }
      return;
    },
    prependTrace({ commit }, value) {
      commit('PREPEND_TRACE', value);
    },
    logout({ commit }) {
      commit('SET_LOGGED_IN_DATA');
      commit('SET_ACCESS_TOKEN');
      deleteLocalStorage('accessToken');
    }
  }
})
//local storage functions
function setLocalStorage(key, value) {
  //ensure data as an JSON string
  let data = (typeof value === 'string') ? value : JSON.stringify(value);
  //store value in localStorage so it will be persistent over page reloads
  localStorage.setItem(key, data);
}
function deleteLocalStorage(key) {
  //remove from local storage
  localStorage.removeItem(key);
}
