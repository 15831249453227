<template>
  <v-dialog
    v-model="show"
    style="z-index:1000000"
    :fullscreen="useFullscreenDialog"
    :max-width="useFullscreenDialog ? 10000 : 400"
  >
    <v-card>
      <v-card-title v-if="propTitle" class="text-h6 text-center pt-4 pb-0">{{ propTitle }}</v-card-title>
      <v-card-text class="text-center" :class="{ 'pt-4': !propTitle }">
        {{ propText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onCloseBtn">Stäng</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { watch, onMounted, ref } from '@vue/composition-api'
import useCore from '@/helpers/core'
import { appBus } from '../main'

export default {
  name: 'Alert',
  setup() {
    const store = require('@/store/store').default;

    const { tellKobra } = useCore();

    const useFullscreenDialog = store.getters.useFullscreenDialog;

    const show = ref(false);
    const propTitle = ref('');
    const propText = ref('');
    const propOnClose = ref(() => {});

    const onCloseBtn = () => {
      propOnClose.value();
      show.value = false;
    }

    const showAlert = ({ text, onClose, title}) => {
      propTitle.value = title;
      propText.value = text;
      propOnClose.value = onClose;
      show.value = true;
    }

    // watch
    watch(() => show.value, newValue => {
      if (newValue && useFullscreenDialog) {
        tellKobra('scrollToTop');
      }
    });

    const doOnMounted = () => {
      appBus.$on('showAlert', showAlert);
    }

    onMounted(doOnMounted);

    return {
      onCloseBtn,
      useFullscreenDialog,
      show,
      propTitle,
      propText,
      propOnClose,
    }
  }
}
</script>