// Vue 3
// import { createRouter, createWebHistory } from "vue-router"
// Vue 2
import VueRouter from 'vue-router'

import store from '../store/store'
import useCore from '../helpers/core'
const { callApi, pollFrameHeightChange } = useCore();

const routes = [
  // default
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/home/:label?',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter:(to, from, next) => {
      _guard(next);
    },
    props(route) {
      return { ...route.params };
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        //if logged in, go to home
        next({ name: 'home' })
      }
      next()
    }
  },
  {
    path: '/kobra-portal',
    name: 'kobraPortal',
    component: () => import('../views/KobraPortal.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
  },
  {
    path: '/order',
    name: 'orderDashboard',
    component: () => import('../views/Order/OrderDashboard.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/order-put',
    name: 'orderPut',
    component: () => import('../views/Order/OrderPut.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/order-rows/:ohdId',
    name: 'orderRowsEdit',
    component: () => import('../views/Order/OrderRowsEdit.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      //make sure ohdId prop is integer
      const props = { ...route.params }
      props.ohdId = +props.ohdId
      return props;
    },
  },
  {
    path: '/incoming-goods',
    name: 'incomingGoods',
    component: () => import('../views/IncomingGoods/IncomingGoods.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/customer',
    name: 'customerAll',
    component: () => import('../views/Customer/CustomerAll.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/customer/:id',
    name: 'customer',
    component: () => import('../views/Customer/Customer.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      //make sure id prop is integer
      const props = { ...route.params }
      props.id = +props.id
      return props;
    },
  },

  // Event-module

  {
    path: '/events/:evnId?/dashboard',
    name: 'eventDashboard',
    component: () => import('../views/Event/EventDashboard.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params };
      props.evnId = +props.evnId;
      return props;
    },
  },

  // EventPerformers
  {
    path: '/events/:evnId/performers/:id',
    name: 'eventPerformerPut',
    component: () => import('../views/Event/EventPerformerPut.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params }
      props.evnId = +props.evnId;
      props.id = +props.id;
      return props;
    },
  },

  // EventPerformances
  {
    path: '/events/:evnId/performances',
    name: 'eventPerformance',
    component: () => import('../views/Event/EventPerformanceList.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params };
      props.evnId = +props.evnId;
      return props;
    },
  },
  {
    path: '/events/:evnId/performances/:id',
    name: 'eventPerformancePut',
    component: () => import('../views/Event/EventPerformancePut.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params }
      props.evnId = +props.evnId;
      props.id = +props.id;
      return props;
    },
  },

  // EventStages 

  {
    path: '/events/stages',
    name: 'eventStage',
    component: () => import('../views/Event/EventStageList.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/events/stages/:id',
    name: 'eventStagePut',
    component: () => import('../views/Event/EventStagePut.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params };
      props.id = +props.id;
      return props;
    },
  },

  // Events

  {
    path: '/events',
    name: 'event',
    component: () => import('../views/Event/EventList.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
  {
    path: '/events/:id',
    name: 'eventPut',
    component: () => import('../views/Event/EventPut.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params };
      props.id = +props.id;
      return props;
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },

  // Mail
  {
    path: '/mailing/email-message-object/:id',
    name: 'emailMessageObject',
    component: () => import('../views/Mailing/EmailMessageObject.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    },
    props(route) {
      const props = { ...route.params };
      props.id = +props.id || 0;
      return props;
    },
  },

  // PRI specific
  // PRI - fleettec
  {
    path: '/fleettec/article-groups',
    name: 'zArticleGroups',
    component: () => import('../views-fleettec/ZArticleGroups.vue'),
    beforeEnter: (to, from, next) => {
      _guard(next);
    }
  },
]

const _guard = (next) => {
  if (!store.getters.isLoggedIn) {
    //if not logged in, go to home
    next({ name: 'login' });
  }
  next();
}

// Vue 3
// const router = createRouter({
//   history: createWebHistory(),
//   base: process.env.BASE_URL,
//   routes
// })

// Vue 2
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//make sure we load possible localStorage before we enter a route (mainly on first load)
router.beforeEach((to, from, next) => {
  const toName = to.name;
  // console.log('router.beforeEach', toName, store.state.firstRouterHasLoaded);
  if (store.state.firstRouterHasLoaded) {
    //this is not initial router load, just go on
    next();
    return;
  }
  //set firstRouterHasLoaded to true so we do not consider this again
  store.dispatch('setFirstRouterHasLoaded')
    .then(() => {
    //this is the initial router load
    // console.log("on first router load");
    const isInIframe = window.location !== window.parent.location;
    if (isInIframe) {
      // this is just so that hitting the back button in Kobra when vajper is embedded should work and not display full layout mode
      // NOTE! both layoutMode and targetOrigin are possibly overridden at KobraPortal.vue if thats the destination
      store.dispatch('setLayoutMode', 'embedded');
      process.env.NODE_ENV == 'development'
      const targetOrigin = process.env.NODE_ENV == 'development' ? 'http://kobra.webomaten.local' : 'https://kobra.webomaten.se';
      store.dispatch('setTargetOrigin', targetOrigin);
      // this is not only for back button though :)
      pollFrameHeightChange();
    }
    //get possible accessToken from localstorage
    const accessToken = localStorage.getItem('accessToken')
    if (!accessToken) {
      // console.log('has no accessToken', accessToken)
      //not logged in - proceed and let the respective "route guards" determine where to go
      next();
      return;
    }
    //store accessToken in store
    // console.log('has accessToken', accessToken);
    let wasLoggedIn = false;
    let noMoreThens = false;
    store.dispatch("setAccessToken", accessToken)
      .then(() => {
        // when accessToken is stored in vuex
        switch (toName) {
          case 'kobraPortal':
            // do api calls in KobraPortal.vue
            noMoreThens = true;
            next();
            return;
        }
        //try to fetch loggedInData for accessToken, winId and priId
        return callApi({
          method: 'get',
          path: '/cms/auth/get-logged-in-data',
          accessToken,
          winId: localStorage.getItem('winId'),
          priId: localStorage.getItem('priId'),
          silent: true,
        });
      })
      .then(apiResponse => {
        if (noMoreThens) {
          return;
        }
        // console.log('get-logged-in-data apiResponse', apiResponse);
        if (apiResponse.loggedInData) {
          //success - call action onAuth in store
          wasLoggedIn = true;
          let dataToDispatch = {
            loggedInData: apiResponse.loggedInData
          }
          return store.dispatch('onAuth', dataToDispatch)
        }
        //non success - make sure we logout and go home
        return store.dispatch('logout')
      })
      .then(() => {
        if (noMoreThens) {
          return;
        }
        if (wasLoggedIn) {
          // ok to go to next
          next();
          return;
        }
        // if not logged in - go home
        next({ name: 'home' });
        return;
      })
      .catch(error => {
        console.log(error.toString())
        //error - logout and go home
        store.dispatch('logout');
        //go home
        next({ name: 'home' });
        return;
      });
    });
});

export default router;
