<template>
  <v-overlay
    :value="overlay"
    style="z-index:1000000"
  >
    <div class="d-flex justify-center align-center flex-column">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <div v-if="overlayMessage.length" class="text-body-1 text-center mt-4">{{ overlayMessage }}</div>
    </div>
  </v-overlay>
</template>
<script>
import { appBus } from '../main';

export default {
  data() {
    return {
      overlay: false,
      overlayMessage: ''
    }
  },
  methods: {
  },
  created() {
    appBus.$on('overlay', (data) => {
      if (data) {
        this.overlayMessage = data.message || ''
        this.overlay = true
      } else {
        this.overlay = false
      }
    })
  }
}
</script>