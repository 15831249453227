import axios from 'axios'
import store from '../store/store'
import useUrl from '../helpers/url'
import { appBus } from '../main'
// import { useRouter } from 'vue-router'

//state
// const state = reactive({
//   initialized: false,
//   loggedInData: null,
//   accessToken: null,
//   firstRouterHasLoaded: false,
// });

export default function() {
  // Vue 2
  const router = require('@/router/router').default;
  //get current locale
  // const router = useRouter();

  const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  const { composeApiUrl } = useUrl();

  const logToConsole = (...args) => {
    console.log(...args);
  }

  const isBlank = value => {
    return typeof value === 'undefined' || value === null || value.toString().trim().length == 0;
  }

  const hasProperty = (object, property) => {
    return Object.prototype.hasOwnProperty.call(object, property)
  }

  const onApiResponse = (res, silent) => {
    if (res.status == 200) {
      //success
      if (res.data && res.data.messages && res.data.messages.length) {
        // display info message/s
        showMessage(parseMessages(res.data.messages), false, 'onApiResponse success');
      }
    } else if (onNonSuccessApiCall(res) && !silent) {
      showMessage(composeErrorMessage(res, 'Okänt fel (43)'), false, 'onApiResponse unknown error');
    }
  }

  const showMessage = (message, sticky = false, referer = 'unknown') => {
    appBus.$emit('showMessage', message, sticky, referer);
  }
  const closeMessage = () => {
    appBus.$emit('closeMessage');
  }

  const showAlert = (config) => {
    appBus.$emit('showAlert', config);
  }
  const closeAlert = () => {
    appBus.$emit('closeAlert');
  }

  const composeErrorMessage = (res, defaultMessage = 'Okänt fel (40)') => {
//      console.log('composeErrorMessage', res);
    return res.data.errors ? parseMessages(res.data.errors) : defaultMessage;
  }

  const onNonSuccessApiCall = (res) => {
    console.log('onNonSuccessApiCall', res);
    switch (res.status) {
      //unauthorized
      case 401:
        //logout
        store.dispatch('logout');
        if (res.data.errors) {
          console.log(parseMessages(res.data.errors, 'js'));
        }
        //go home
        this.$router.replace({ name: 'home' });
        return false;
    }
    return true;
  }

  const setProgress = (show = false, message = null) => {
    if (show) {
      appBus.$emit('overlay', { message });
    } else {
      appBus.$emit('overlay');
    }
  }

  const parseMessages = (messages, target = 'html') => {
    let delimiter = '<br>';
    switch (target) {
      case 'js':
        delimiter = '/n';
      break;
    }
    return messages.join(delimiter);
  }

  const displayAxiosCatchError = (error) => {
    console.log('displayAxiosCatchError error', error);
    console.log('displayAxiosCatchError error.response', error.response);
    let message;
    let sticky = false;
    if (error.response) {
      // Request made and server responded
      const responseData = error.response.data;
      //now we can read the hardcoded message property
      message = responseData.message;
      sticky = !!responseData.sticky
      // console.log(error.response.status);
      // console.log(error.response.headers);
    // } else if (error.request) {
    //   console.log('error.request');
    //   // The request was made but no response was received
    //   console.log(error.request);
    // } else {
    //   // Something happened in setting up the request that triggered an Error
    //   console.log('plain error', error.message);
    } else {
      message = error.toString();
    }
    showMessage(message, sticky, 'displayAxiosCatchError');
  }

  const callApi = async ({
    path,
    method,
    data = null,
    formData = null,
    silent = false,
    language = null,
    accessToken = null,
    winId = null,
    priId = null,
    doLog = false,
  } = {}) => {
    return new Promise((resolve, reject) => {
      // set defaults for some params
      if (!language) {
        language = store.state.language;
      }
      if (!accessToken) {
        // there is support for invoking accessToken to override possible null value in store
        accessToken = store.state.accessToken;
      }
      if (!winId && store.state.loggedInData) {
        winId = store.state.loggedInData.winId;
      }
      if (!priId && store.state.loggedInData) {
        priId = store.state.loggedInData.priId;
      }

      //compose headers
      const headers = {
        'Content-Type': formData ? 'multipart/form-data;charset=UTF-8' : 'application/json;charset=UTF-8',
        'X-Locale': language,
      }
      if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken;
      }
      if (winId) {
        headers['X-WinId'] = winId;
      }
      if (priId) {
        headers['X-PriId'] = priId;
      }

      const url = composeApiUrl(path);
      const config = {
        method: method,
        url: url,
        headers: headers,
      }
      if (data) {
        config.data = data;
      } else if (formData) {
        config.data = formData;
      }

      if (doLog) {
        console.log('axios ' + config.method, config);
      }

      axios(config)
        .then(response => {
          if (doLog) {
            console.log('axios response', response);
          }
          // call onApiResponse
          onApiResponse(response, silent);
          // resolve response.data
          resolve(response.data);
        })
        .catch(error => {
          if (doLog) {
            console.log('axois error', error);
          }
          if (!silent) {
            // call displayAxiosCatchError
            displayAxiosCatchError(error);
          }
          // reject with success flag
          reject(false);
        });
    });
  }

  const login = async (username, password, language, targetPath) => {
    // callApi
    return callApi({
      method: 'post',
      path: '/cms/public/login-request',
      language,
      data: {
        username,
        password,
      }
    })
      .then(apiResponse => {
        if (apiResponse.validAccess) {
          //valid login
          const dataToDispatch = {
            loggedInData: apiResponse.loggedInData,
            accessToken: apiResponse.token
          }
          console.log('core.login dataToDispatch', dataToDispatch);
          store.dispatch('onAuth', dataToDispatch)
            .then(() => {
              if (targetPath) {
                // go to specific targetPath
                router.replace('/' + decodeURIComponent(targetPath));
              } else {
                // go to home
                router.replace({ name: 'home' });
              }
            })
        } else {
          //invalid login
          showMessage(apiResponse.message, false, 'invalid login');
        }
        return apiResponse;
      })
      .catch(err => {
        return err
      })
  }

  const logout = async () => {
    store.dispatch('SET_LOGGED_OUT')
    deleteLocalStorage('accessToken')
    return
  }

  // debounce function
  const debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      }
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    }
  }

  //local storage functions
  const setLocalStorage = (key, value) => {
    //ensure data as an JSON string
    const data = (typeof value === 'string') ? value : JSON.stringify(value)
    //store value in localStorage so it will be persistent over page reloads
    localStorage.setItem(key, data);
  }
  
  const deleteLocalStorage = (key) => {
    //remove from local storage
    localStorage.removeItem(key)
  }

  // kobra parent
	const postMessageToKobra = messageData => {
		console.log("vajper - PostMessage", messageData, store.state.targetOrigin);
		window.parent.postMessage(messageData, store.state.targetOrigin);
	}

  const tellKobra = (act, data = null) => {
    postMessageToKobra({
      act: act,
      referer: "vajper",
      layoutMode: store.state.layoutMode,
      data,
    });
  }

	const removeElementById = (id) => {
		const el = document.getElementById(id);
		if (el) {
			// remove possible earlier form
			el.parentNode.removeChild(el);
		}
	}

	const ensureFormField = (form, fieldName, fieldValue) => {
		// make sure we stringify objects
		var value = (typeof fieldValue == "object") ? JSON.stringify(fieldValue) : fieldValue;
    console.log('ensureFormField', fieldName, form[fieldName]);
    const inputs = form.elements;
		if (!inputs[fieldName]) {
			// field does not already exist - append it (make sure we use ' around value to support stringified json object with "key" etc
      const inputElement = document.createElement('input');
      inputElement.setAttribute('type', 'hidden');
      inputElement.setAttribute('name', fieldName);
      inputElement.setAttribute('value', value);
      form.appendChild(inputElement);
		} else {
			//field exists - just set value
			inputs[fieldName].value = value;
		}
	}
  
  const createAndSubmitForm = (id, attr, formFields) => {
		removeElementById(id);
		const form = document.createElement("form");
		form.setAttribute("id", id);
		const attr_default = {
			target: "_blank",
			method: "post"
		}
    const formAttr = Object.assign(attr_default, attr);
		form.setAttribute("target", formAttr.target);
		form.setAttribute("method", formAttr.method);
		form.setAttribute("action", formAttr.action);

		for (let formField of formFields) {
			ensureFormField(form, formField.name, formField.value);
		}

    console.log('createAndSubmitForm ready form', form);

		document.body.appendChild(form);

    form.submit();
	}

  const openOrderInKobra = (id, callback) => {
    openKobra({
      xOnLogin: 'order',
      onRedirectUrlVars: [
        {
          name: 'onRedirect_view',
          value: 'single',
        },
        {
          name: 'onRedirect_id',
          value: id,
        },
        {
          name: 'onRedirect_fancybox',
          value: '',
        },
      ],
      callback,
    });
  }

  const openKobra = async ({
    xOnLogin = null,
    onRedirectUrlVars = null,
    callback = null,
  } = {}) => {
    const apiResponse = await callApi({
      method: 'get',
      path: '/cms/auth/open-kobra-credentials',
    });
    const attr = {
      action: process.env.NODE_ENV == 'development' ? 'http://kobra.webomaten.local' : 'https://kobra.webomaten.se',
    }
    const formFields = [
      {
        name: 'x',
        value: 'login',
      },
      {
        name: 'act',
        value: 'loginRequest',
      },
      {
        name: 'view',
        value: 'loginAs',
      },
      {
        name: 'username',
        value: apiResponse.credentials.username,
      },
      {
        name: 'password',
        value: apiResponse.credentials.password,
      },
      {
        name: 'loginAsChecksum',
        value: apiResponse.credentials.loginAsChecksum,
      },
      {
        name: 'winId',
        value: apiResponse.credentials.winId,
      },
      {
        name: 'priId',
        value: apiResponse.credentials.priId,
      },
      {
        name: '____fromVajper',
        value: 1,
      },
    ];
    if (xOnLogin) {
      formFields.push({
        name: 'xOnLogin',
        value: xOnLogin,
      });
    }
    if (onRedirectUrlVars) {
      // append all onRedirectUrlVars to formFields
      formFields.push(...onRedirectUrlVars);
      // also output reference list for onRedirectUrlVars in order to know which extended url vars to extract at kobra
      formFields.push({
        name: 'extendedUrlVar_list',
        value: onRedirectUrlVars.map(onRedirectUrlVar => onRedirectUrlVar.name).join(','),
      });
    }
    createAndSubmitForm('open-kobra', attr, formFields);
    if (callback) {
      callback();
    }
  }

  let lastFrameHeight = 0;

  const	pollFrameHeightChange = () => {
    // console.log('pollFrameHeightChange');
    const body = document.body;
    const html = document.documentElement;
    // console.log('body.scrollHeight', body.scrollHeight);
    // console.log('body.offsetHeight', body.offsetHeight);
    // console.log('html.clientHeight', html.clientHeight);
    // console.log('html.scrollHeight', html.scrollHeight);
    // console.log('html.offsetHeight', html.offsetHeight);
    const newHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    if (lastFrameHeight !== newHeight) {
      updateIframeHeight(newHeight);
    }
    lastFrameHeight = newHeight;
    setTimeout(pollFrameHeightChange, 1000);
  }
  
  const updateIframeHeight = newHeight => {
    tellKobra('setHeight', { height: newHeight });
  }

  const convertJsonToHtml = text => {
    return text.replace(/\n/g, '<br>');
  }

  return {
    logToConsole,
    onApiResponse,
    displayAxiosCatchError,
    callApi,
    login,
    logout,
    setLocalStorage,
    deleteLocalStorage,
    onNonSuccessApiCall,
    composeErrorMessage,
    showMessage,
    closeMessage,
    debounce,
    hasProperty,
    postMessageToKobra,
    tellKobra,
    setProgress,
    parseMessages,
    isBlank,
    convertJsonToHtml,
    pollFrameHeightChange,
    updateIframeHeight,
    openOrderInKobra,
    delay,
    showAlert,
    closeAlert,
  }
}
